import theme from "@styles/theme"
import styled from "styled-components"

export const ShadowImage = styled.div`
  .klipfolio-image-wrapper {
    border-radius: 0.5rem;
    div {
      box-shadow: ${theme.shadow.default};
    }
    max-height: ${props => props.maxHeight};
    img {
      object-position: top;
    }
  }
`

export const FloatingImage = styled.div`
  .klipfolio-image-wrapper {
    position: sticky;
    top: 6rem;
    border-radius: 0.5rem;
    div {
      box-shadow: ${theme.shadow.default};
    }
  }
`
